import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import CustomHero from './CustomHero/CustomHero';
import CustomOurServices from './CustomOurServices/CustomOurServices';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import CustomFeatures from './CustomFeatures/CustomFeatures';
import CustomAboutUs from './CustomAboutUs/CustomAboutUs';
import CustomBuildYourEvent from './CustomBuildYourEvent/CustomBuildYourEvent';
import CustomServices from './CustomServices/CustomServices';
import CustomEasyProcesses from './CustomEasyProcesses/CustomEasyProcesses';
import CustomPricing from './CustomPricing/CustomPricing';
import CustomJoinTheMasters from './CustomJoinTheMasters/CustomJoinTheMasters';

const CustomLandingPage = props => {
  const {
    scrollingDisabled,
    onManageDisableScrolling,
    searchInProgress,
    searchListingsError,
  } = props;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <CustomHero />
        <CustomOurServices />
        <CustomFeatures />
        <CustomAboutUs />
        <CustomBuildYourEvent />
        <CustomServices />
        <CustomEasyProcesses />
        <CustomPricing />
        <CustomJoinTheMasters />
      </LayoutSingleColumn>
    </Page>
  );
};

// const mapStateToProps = state => {
//     const {
//         currentPageResultIds,
//         searchInProgress,
//         searchListingsError,
//     } = state.CustomLandingPage;
//     const listings = getListingsById(state, currentPageResultIds);

//     return {
//         listings,
//         scrollingDisabled: isScrollingDisabled(state),
//         searchInProgress,
//         searchListingsError,
//     };
// };

// const mapDispatchToProps = dispatch => ({
//     onManageDisableScrolling: (componentId, disableScrolling) =>
//         dispatch(manageDisableScrolling(componentId, disableScrolling)),
// });

// const CustomLandingPage = compose(
//     connect(
//         mapStateToProps,
//         mapDispatchToProps
//     )
// )(CustomLandingPageComponent);

export default CustomLandingPage;
