import React from 'react';
import css from './CustomEasyProcesses.module.css';
import { FormattedMessage } from 'react-intl';
import CustomEasyProcessIcon1 from '../../../assets/customEasyProcesses1.png';
import CustomEasyProcessIcon2 from '../../../assets/customEasyProcesses2.png';
import CustomEasyProcessIcon3 from '../../../assets/customEasyProcesses3.png';
import CustomEasyProcessIcon4 from '../../../assets/customEasyProcesses4.png';

const CustomEasyProcesses = () => {
  return (
    <section className={css.sectionEasyProcesses}>
      <div className={css.container}>
        <div className={css.leftDiv}>
          <p className={css.header}>
            <FormattedMessage id="CustomEasyProcesses.header" />
          </p>
          <p className={css.title}>
            <FormattedMessage id="CustomEasyProcesses.title" />
          </p>
          <p className={css.description}>
            <FormattedMessage id="CustomEasyProcesses.description1" />
            <b>
              <FormattedMessage id="CustomEasyProcesses.description2" />
            </b>
            <FormattedMessage id="CustomEasyProcesses.description3" />
            <b>
              <FormattedMessage id="CustomEasyProcesses.description4" />
            </b>
            <FormattedMessage id="CustomEasyProcesses.description5" />
          </p>
        </div>
        <div className={css.rightDiv}>
          <div className={css.sectionContainer}>
            <img className={css.image} src={CustomEasyProcessIcon1} alt="" />
            <div className={css.text}>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item1Title" />
              </p>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item1Description" />
              </p>
            </div>
          </div>
          <div className={css.sectionContainer}>
            <img className={css.image} src={CustomEasyProcessIcon2} alt="" />
            <div className={css.text}>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item2Title" />
              </p>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item2Description" />
              </p>
            </div>
          </div>
          <div className={css.sectionContainer}>
            <img className={css.image} src={CustomEasyProcessIcon3} alt="" />
            <div className={css.text}>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item3Title" />
              </p>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item3Description" />
              </p>
            </div>
          </div>
          <div className={css.sectionContainer}>
            <img className={css.image} src={CustomEasyProcessIcon4} alt="" />
            <div className={css.text}>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item4Title" />
              </p>
              <p>
                <FormattedMessage id="CustomEasyProcesses.Item4Description" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomEasyProcesses;
