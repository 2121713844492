import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, NamedLink, PrimaryButton } from '../../../components';
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer';
import css from './CustomHero.module.css';
import HeroImage from '../../../assets/heroImage.png';

const CustomHero = () => {
  return (
    <SectionContainer className={css.customHeroSection}>
      <div className={css.contentContainer}>
        <div className={css.leftColumn}>
          <div className={css.textsWrapper}>
            <Heading as="h1" className={css.textTitle}>
              <FormattedMessage id="CustomHeroSection.title" />
            </Heading>
            <p className={css.textDesc}>
              <FormattedMessage id="CustomHeroSection.description" />
            </p>
          </div>

          <NamedLink name="SearchPage" className={css.linkBtn}>
            <PrimaryButton className={css.customButton}><FormattedMessage id="CustomHeroSection.CTA" /></PrimaryButton>
          </NamedLink>
        </div>
        <div className={css.rightColumn}>
          <img src={HeroImage} alt="" className={css.heroImage} />
        </div>
      </div>
    </SectionContainer>
  );
};

export default CustomHero;
