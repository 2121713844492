import React from 'react';
import css from './CustomJoinTheMasters.module.css';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../../components';

const CustomJoinTheMasters = () => {
  return (
    <section className={css.sectionJoinTheMasters}>
      <div className={css.container}>
        <h2 className={css.header}>
          <FormattedMessage id="CustomJoinTheMasters.header" />
        </h2>
        <p className={css.description}>
          <FormattedMessage id="CustomJoinTheMasters.description1" /><br />
          <FormattedMessage id="CustomJoinTheMasters.description2" />
        </p>
        <NamedLink name="SignupPage" className={css.ctaButton}>
          <FormattedMessage id="CustomJoinTheMasters.CTA" />
        </NamedLink>
      </div>
    </section>
  );
};

export default CustomJoinTheMasters;
