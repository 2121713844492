import React from 'react';
import css from './CustomBuildYourEvent.module.css';
import BuildYourEvent1 from '../../../assets/buildYourEvent1.png';
import BuildYourEvent2 from '../../../assets/buildYourEvent2.png';
import BuildYourEvent3 from '../../../assets/buildYourEvent3.png';
import BuildYourEvent4 from '../../../assets/buildYourEvent4.png';

const CustomBuildYourEvent = () => {
  return (
    <section className={css.sectionBuildYourEvent}>
      <div className={css.container}>
        <div className={css.imagesContainer}>
          <img className={css.image} src={BuildYourEvent1} alt="Image 1" />
          <img className={css.image} src={BuildYourEvent2} alt="Image 2" />
          <img className={css.image} src={BuildYourEvent3} alt="Image 3" />
          <img className={css.image} src={BuildYourEvent4} alt="Image 4" />
        </div>
      </div>
    </section>
  );
};

export default CustomBuildYourEvent;