import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, NamedLink, PrimaryButton } from '../../../components';
import css from './CustomAboutUs.module.css';
import AboutUsImage from '../../../assets/aboutUs.png';

const CustomAboutUs = () => {
  return (
    <section className={css.sectionAboutUs}>
      <div className={css.container}>
        <div className={css.leftColumn}>
          <img src={AboutUsImage} alt="About Us" className={css.image} />
        </div>
        <div className={css.rightColumn}>
          <p className={css.header}>
            <FormattedMessage id="CustomAboutUs.header" />
          </p>
          <p className={css.title}>
            <FormattedMessage id="CustomAboutUs.title" />
          </p>
          <p className={css.description}>
            <FormattedMessage id="CustomAboutUs.description1" />
          </p>
          <p className={css.description}>
            <FormattedMessage id="CustomAboutUs.description2" /><b> <FormattedMessage id="CustomAboutUs.description2a" /></b>.
          </p>
          <a href="/p/about" className={css.ctaButton}>
            <button className={css.ctaButtonText}>
              <FormattedMessage id="CustomAboutUs.cta" />
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default CustomAboutUs;
