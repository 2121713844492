import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../../components';
import css from './CustomPricing.module.css';
import Crown from '../../../assets/crown.png';
import Reviews from '../../../assets/reviews.png';
import Ratings from '../../../assets/ratings.png';

const CustomPricing = () => {
  return (
    <section className={css.sectionPricing}>
      <div className={css.container}>
        <div className={css.content}>
          <div className={css.headerSection}>
            <h3 className={css.subheading}>
              <FormattedMessage id="CustomPricing.header" />
            </h3>
            <h2 className={css.heading}>
              <FormattedMessage id="CustomPricing.title" />
            </h2>
            <p className={css.description}>
              <FormattedMessage id="CustomPricing.description" />
            </p>
            <div>
              <img src={Reviews} alt="Reviews" className={css.reviewsIcon} />
            </div>
            <div>
              <img src={Ratings} alt="Ratings" className={css.ratingsIcon} />
            </div>
          </div>

          <div className={css.cardContainer}>
            <div className={css.card1}>
              <p className={css.cardTitle}>
                <FormattedMessage id="CustomPricing.card1header" />
              </p>
              <p className={css.cardPrice}>
                <FormattedMessage id="CustomPricing.card1price" />
              </p>
              <p className={css.cardDescription}>
                <FormattedMessage id="CustomPricing.card1description" />
              </p>
              <NamedLink name="SignupPage" className={css.cardButton}>
                <FormattedMessage id="CustomPricing.starterCTA" />
              </NamedLink>
              <ul className={css.cardFeatures}>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature1" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature2" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature3" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature4" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature5" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature6" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.starterFeature7" />
                </li>
              </ul>
            </div>

            <div className={css.card2}>
              <p className={css.cardTitle}>
                <FormattedMessage id="CustomPricing.card2header" />
                <img src={Crown} alt="Crown" className={css.crown} />
              </p>
              <p className={css.cardPrice}>
                <FormattedMessage id="CustomPricing.card2price" />
              </p>
              <p className={css.cardDescription}>
                <FormattedMessage id="CustomPricing.card2description" />
              </p>
              <button disabled className={css.cardButton}>
                <FormattedMessage id="CustomPricing.professionalCTA" />
              </button>
              <ul className={css.cardFeatures}>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature1" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature2" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature3" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature4" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature5" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature6" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature7" />
                </li>
                <li>
                  <FormattedMessage id="CustomPricing.professionalFeature8" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomPricing;
