import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, NamedLink, PrimaryButton } from '../../../components';
import SectionContainer from '../../PageBuilder/SectionBuilder/SectionContainer';
import css from './CustomOurServices.module.css';
import Services1 from '../../../assets/services1.png';
import Services2 from '../../../assets/services2.png';
import Services3 from '../../../assets/services3.png';
import Services4 from '../../../assets/services4.png';

const CustomOurServices = () => {
  return (
    <section className={css.sectionOurServices}>
      <div className={css.container}>
        <div className={css.headerText}>
          <p className={css.header}>
            <FormattedMessage id="CustomOurServices.header" />
          </p>
          <p className={css.title}>
            <FormattedMessage id="CustomOurServices.title" />
          </p>
          <p className={css.description}>
            <FormattedMessage id="CustomOurServices.description" />
          </p>
        </div>
        <div className={css.imagesContainer}>
          <div className={css.imageItem}>
            <img src={Services1} alt="Image 1" />
            <p className={css.serviceTitle}>
              <FormattedMessage id="CustomOurServices.service1" />
            </p>
            <NamedLink name="SearchPage" className={css.linkBtn}>
              <p className={css.cta}>
                <FormattedMessage id="CustomOurServices.CTA" />
              </p>
            </NamedLink>
          </div>
          <div className={css.imageItem}>
            <img src={Services2} alt="Image 2" />
            <p className={css.serviceTitle}>
              <FormattedMessage id="CustomOurServices.service2" />
            </p>
            <NamedLink name="SearchPage" className={css.linkBtn}>
              <p className={css.cta}>
                <FormattedMessage id="CustomOurServices.CTA" />
              </p>
            </NamedLink>
          </div>
          <div className={css.imageItem}>
            <img src={Services3} alt="Image 3" />
            <p className={css.serviceTitle}>
              <FormattedMessage id="CustomOurServices.service3" />
            </p>
            <NamedLink name="SearchPage" className={css.linkBtn}>
              <p className={css.cta}>
                <FormattedMessage id="CustomOurServices.CTA" />
              </p>
            </NamedLink>
          </div>
          <div className={css.imageItem}>
            <img src={Services4} alt="Image 4" />
            <p className={css.serviceTitle}>
              <FormattedMessage id="CustomOurServices.service4" />
            </p>
            <NamedLink name="SearchPage" className={css.linkBtn}>
              <p className={css.cta}>
                <FormattedMessage id="CustomOurServices.CTA" />
              </p>
            </NamedLink>
          </div>
        </div>
        <NamedLink name="SearchPage">
          <button className={css.ctaButton}>
            <FormattedMessage id="CustomOurServices.CTAAll" />
          </button>
        </NamedLink>
      </div>
    </section>
  );
};

export default CustomOurServices;
