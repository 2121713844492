import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './CustomFeatures.module.css';
import features1 from '../../../assets/features1.png';
import features2 from '../../../assets/features2.png';
import features3 from '../../../assets/features3.png';
import features4 from '../../../assets/features4.png';
import features5 from '../../../assets/features5.png';
import features6 from '../../../assets/features6.png';

const CustomFeatures = () => {
  return (
    <section className={css.sectionFeatures}>
      <div className={css.container}>
        <div className={css.headerText}>
          <p className={css.header}>
            <FormattedMessage id="CustomFeatures.header" />
          </p>
          <p className={css.title}>
            <FormattedMessage id="CustomFeatures.title" />
          </p>
          <p className={css.description}>
            <FormattedMessage id="CustomFeatures.description" />
          </p>
        </div>
        <div className={css.featuresContainer}>
          <div className={css.featureRow}>
            <div className={css.featureItem}>
              <div className={css.featureImage}>
                <img className={css.image} src={features1} alt="Feature 1" />
              </div>
              <div className={css.featureContent}>
                <p className={css.featureTitle}>
                  <FormattedMessage id="CustomFeatures.feature1Title" />
                </p>
                <p className={css.featureDescription}>
                  <FormattedMessage id="CustomFeatures.feature1Description" />
                </p>
              </div>
            </div>
            <div className={css.featureItem}>
              <div className={css.featureImage}>
                <img className={css.image} src={features2} alt="Feature 2" />
              </div>
              <div className={css.featureContent}>
                <p className={css.featureTitle}>
                  <FormattedMessage id="CustomFeatures.feature2Title" />
                </p>
                <p className={css.featureDescription}>
                  <FormattedMessage id="CustomFeatures.feature2Description" />
                </p>
              </div>
            </div>
          </div>
          <div className={css.featureRow}>
            <div className={css.featureItem}>
              <div className={css.featureImage}>
                <img className={css.image} src={features3} alt="Feature 3" />
              </div>
              <div className={css.featureContent}>
                <p className={css.featureTitle}>
                  <FormattedMessage id="CustomFeatures.feature3Title" />
                </p>
                <p className={css.featureDescription}>
                  <FormattedMessage id="CustomFeatures.feature3Description" />
                </p>
              </div>
            </div>
            <div className={css.featureItem}>
              <div className={css.featureImage}>
                <img className={css.image} src={features4} alt="Feature 4" />
              </div>
              <div className={css.featureContent}>
                <p className={css.featureTitle}>
                  <FormattedMessage id="CustomFeatures.feature4Title" />
                </p>
                <p className={css.featureDescription}>
                  <FormattedMessage id="CustomFeatures.feature4Description" />
                </p>
              </div>
            </div>
          </div>
          <div className={css.featureRow}>
            <div className={css.featureItem}>
              <div className={css.featureImage}>
                <img className={css.image} src={features5} alt="Feature 5" />
              </div>
              <div className={css.featureContent}>
                <p className={css.featureTitle}>
                  <FormattedMessage id="CustomFeatures.feature5Title" />
                </p>
                <p className={css.featureDescription}>
                  <FormattedMessage id="CustomFeatures.feature5Description" />
                </p>
              </div>
            </div>
            <div className={css.featureItem}>
              <div className={css.featureImage}>
                <img className={css.image} src={features6} alt="Feature 6" />
              </div>
              <div className={css.featureContent}>
                <p className={css.featureTitle}>
                  <FormattedMessage id="CustomFeatures.feature6Title" />
                </p>
                <p className={css.featureDescription}>
                  <FormattedMessage id="CustomFeatures.feature6Description" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomFeatures;
