import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, NamedLink, PrimaryButton } from '../../../components';
import css from './CustomServices.module.css';

const CustomServices = () => {
  return (
    <section className={css.sectionServices}>
      <div className={css.container}>
        <div className={css.headerText}>
          <p className={css.header}>
            <FormattedMessage id="CustomServices.header" />
          </p>
          <p className={css.title}>
            <FormattedMessage id="CustomServices.title" />
          </p>
          <p className={css.description}>
            <FormattedMessage id="CustomServices.description" />
          </p>
        </div>
      </div>
    </section>
  );
};

export default CustomServices;
